<template>
  <div id="app">
    <a-locale-provider :locale="locale">
      <router-view />
    </a-locale-provider>
  </div>
</template>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
export default {
  name: "App",
  data() {
    return {
      locale: zhCN,
    };
  }
};
</script>

<style lang="less">
	@media screen and (max-width: 1280px) {
	    body {
	        width: 1500px !important;
	    }
	}
	* {
		margin: 0px;
		padding: 0px;
		color: #000;
	}
	.ant-modal-title {
		font-weight: 700;
	}
	div,
	p {
		margin: 0px;
		padding: 0px;
	}
	
	body {
		background-color: #f5f4f5 !important;
	}
	
	.datezdp {
		background-color: transparent;
		color: #fff;
	
		input {
			background-color: transparent;
			border: none;
			color: #333;
		}
		.anticon  {
			color: #333;
		}
	}
</style>
