import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.less'
import 'babel-polyfill'; //es5
import 'es6-promise/auto'
import * as echarts from 'echarts'
Vue.config.productionTip = false

import { message } from 'ant-design-vue'

Vue.prototype.$message = message;

Vue.use(Antd);

Vue.prototype.$echarts = echarts

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')




