import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/components/HomeView.vue'

Vue.use(VueRouter)

const routes = [{
	path: '/',
	component: HomeView,
	redirect: '/home',
	children: [{
		path: '/home',
		name: 'home',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/home.vue')
	}, {
		path: '/login',
		name: 'login',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/login.vue')
	}]
}]

const router = new VueRouter({
	routes: routes,
	mode: 'hash'
})




export default router